import * as React from "react"
import Demo from "../components/Demo"
import Founder from "../components/Founder"
import RoadMap from "../components/RoadMap"
// import fundingImage from "../images/funding.jpeg"
// import endingImage from "../images/ending.jpeg"
// import roadMapImage from "../images/roadmap.jpeg"
// import marketImage from "../images/market.jpeg"
import logo from "../images/logo.png"
// import 'mvp.css'
import './board9.cn.css'

// styles
const pageStyles = {
  // color: "#232129",
  padding: 35,
  // fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

const logoStyles = {
  width: "100px"
}
// data


// markup
const IndexPage = () => {
  return (
    <main style={pageStyles} id="app">
      <title>Business Plan</title>
      <header> <h1>商业计划书</h1> <img src="http://nbimgs.breadtrip.com/8UkomLvO8GUbeU219CpPklShaGXhmXnK/logo.png" style={logoStyles}/> <p className="mt-5"> <small>updated at 2022-03-07</small> </p> </header>
      <hr></hr>
      <header> <h1>项目说明</h1> </header>
      <h3>BOARD9</h3>
      <ul>
        <li>BOARD9是一款帮助企业以100倍速度搭建内部工具的服务。</li>
        <li>国内有近700万+的工程师和应用是我们的潜在客户。</li>
      </ul>
      
      
      <h3 className="mt-5">公司长期愿景</h3>
      <p>我们致力于为科技企业提供创新的内部效率解决方案</p>
      
      <hr></hr>
      <header> 
        <h1>市场概况</h1> 
        
      </header>
      <ul>
        <li>预估现存750万Dashboard，每年新增150万（年增长20%计）</li>
        <li>换算成潜在市场规模：现存450亿，每年新增90亿（6000元/年计）</li>
      </ul>
      <figure> <img src="http://nbimgs.breadtrip.com/7xh4nRubNOpR5lj5ysVgGIQn8VQojp8W/market.jpeg" alt="Market" /> </figure>


      {/* <hr></hr>
      <header> <h1>参考公司情况</h1>
        <p>分别验证：低代码接受度、目标人群的消费意愿、直接的对标产品表现</p>
       </header>
      
      <ul>
        <li>Wix</li>
        <p>老牌低代码厂商，年营收约12亿美金，展示了低代码的良好接受度</p>
        <li>CircleCI & Popsql</li>
        <p>CircleCI，客户群体相同，总计融资超过3亿美金</p>        
        <p>Popsql，客户群体相同，近年发展迅速，连续融资超过3000万美金</p>
        <li>Retool</li>
        <p>对标品，融资近一亿美金，商业化能力良好</p>
      </ul> */}

      
      <hr></hr>
      <header> <h1>WHY</h1> </header>
            
        <h3>Q：为什么是这个市场？</h3>
        <h3>A：市场大 + 自研成本高 + 竞品少</h3>
        <br></br>
        <p>企业自研成本高，急需相关产品</p>
        <p>举例：在国外想替换Stripe的成本要远高于国内</p>
        <br></br>
        <p>对技术和产品的要求高，导致替代品会很少</p>
        <p>举例：在线文档的可替代品远多于低代码类产品</p>

        <br></br>
        <br></br>

        <h3>Q：为什么是我们的产品？</h3>
        <h3>A：底层范式支撑+清晰的产品理念</h3>
        <br></br>
        <p>范式的支撑</p>
        <ul>
          <li>错误示范：腾讯微搭通过在工程上的努力在强制实现低代码的特征。</li>
          <li>正确示范：RISC/CISC芯片的发展</li>
        </ul>        
        <br></br>
        <p>清晰的产品理念 举例：</p>
        <ul>
          <li>0 新概念</li>
          <li>0 Debug需要</li>
          <li>15秒即可上手</li>
          <li>js/sql全拓展</li>
        </ul>
        <br></br>
        <p>PLG</p>
        <ul>
          <li>产品矩阵</li>
          <li>团队的2C经验</li>
        </ul>
        
      

      <Founder></Founder>
     

      <hr></hr>
      <header>
        <h1>融资需要</h1>
        <p>本轮计划出让10%～20%的股权，融资100～200万美金。</p>
      </header>
      <figure> <img src="http://nbimgs.breadtrip.com/ERERuMvi0ufHC1zpoG123zmjiHiopGIA/funding.jpeg" alt="Funding" /> </figure>

      
      <div class="GoToMarket">
      <hr></hr>
      <header> <h1>Go To Market</h1> </header>

      <h3>产品策略</h3>
      <ul>
      <li>上手速度快</li>
      <p><i>✅ 15秒快速体验 ✅ 0新概念 ✅ 无DEBUG需求</i>
        </p>  
      
      <li>高拓展性</li>
      <p>
        <i>
        ✅ 全数据源支持 ✅ API支持 ✅ SAAS支持 ✅ 原生SQL、JS支持
        </i>
      </p>
      
      <li>安全稳定</li>
      <p>
        <i>
        ✅ 原生对私有化部署的支持 ✅ 开放：Open Issues / Open Ticket
        </i>
      </p>
      
      <li>产品矩阵</li>
      <p>
        <i>
        ✅ sql9.cn ✅ mock9.cn
        </i>
      </p>
      
      </ul>

      <h3 className="mt-5">市场策略</h3>
      <ul>
        <li>小企业 人数少于50</li>
        <i>
          <p>⚡ 特征：小团队/一级决策 </p>
          <p>⚡ 特征：对技术敏感，愿意尝试新事务，耐性较少 </p>
          <p>✅ 快速上手</p>
          <p>✅ 设置议程传播</p>
          <p>✅ 积极参与社群</p>
        </i>
        <li className="mt-5">中型企业 人数少于400</li>
        <i>
          <p>⚡ 特征：团队还在建设中/两级决策</p>
          <p>⚡ 特征：业务发展快，变化快，人手怎么也不够用</p>
          <p>⚡ 特征：大量后台需求无法满足</p>
          <p>✅ 开源策略，吸引主动寻找的客户</p>
          <p>✅ 私有化部署</p>
          <p>✅ 满足全功能高扩展性</p>
          <p>✅ 产品矩阵，降低流量成本</p>
          <p>✅ 大企业背书</p>
        </i>
        <li className="mt-5">对大型企业 或已上市</li>        
        <i>
          <p>⚡ 特征：审批流程长</p>
          <p>⚡ 特征：数据安全重中之重</p>
          <p>✅ 内置于云厂商</p>
          <p>✅ 大投资方背书</p>
          <p>✅ 商务团队</p>
        </i>
      </ul>
      </div>

    <RoadMap></RoadMap>
        
    {/* <figure>
    <img src="http://nbimgs.breadtrip.com/ghqdPzeyR8uP7sAX67cPIH3eWQ2Osw8b/roadmap.jpeg" alt="Roadmap" />
    </figure> */}

    <Demo></Demo>

    <hr></hr> <header> <h1>Thanks</h1> </header>

    {/* <figure> <img src="http://nbimgs.breadtrip.com/heGj4UEdqc81dfNj8KvzVwLgwL0E1IVe/ending.jpeg" alt="ENDING" /> </figure> */}
    </main>
  )
}

export default IndexPage
